<template>
  <div>

  </div>
</template>

<script>
  import Store from 'store'
  export default {
    name: 'clear_all',
    components: {

    },
    data() {
      return {

      }
    },
    created() {
      Store.clearAll()
      window.alert("清理干净，应该需要重新关注");
    },
  }
</script>

<style scoped lang="less">

</style>
